
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.modal-dialog-centered {
  display: flex;
  align-items: flex-start;
  min-height: calc(100vh - var(--bs-modal-margin) * 3);
  @include breakpoint('medium') {
    align-items: center;
  }
}

.modal-content-wrapper {
  padding: 14px 4px 4px;
}

.modal-no-button,
.modal-yes-button {
  width: 100%;

  @include breakpoint('medium') {
    width: auto;
  }
}

.clear-yes-button-wrap {
  position: relative;
  .spinner-border {
    position: absolute;
    display: inline-block;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: -0.125em;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: 0.75s linear infinite spinner-border;
    animation: 0.75s linear infinite spinner-border;
  }
}

.modalContent {
  max-width: 600px;
  margin: 70px auto;
}
