
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.modal-dialog-wrap {
  max-width: 700px;
  margin: 0 15px;
  @include breakpoint('medium') {
    margin: auto;
  }
  .modal-title {
    font-size: 22px;
    line-height: 26px;
    font-weight: typography.$bold;
    margin: 0;
  }
  .modal-body-wrap {
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0 -1rem;
    padding: 0 1.5rem 0 1.5rem;
    max-height: calc(70vh - 157px);
    @include breakpoint('medium') {
      max-height: calc(80vh - 67px);
      padding: 0 2rem 0 3rem;
    }
    p {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.015em;
      margin-bottom: 0;
      strong {
        font-weight: typography.$bold;
      }
    }
  }
}

.modal-title-eyebrow {
  h4 {
    margin-bottom: 0;
  }
}
